<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="600"
    scrollable
  >
    <v-card :loading="isLoading">
      <v-card-title class="caption">
        <h3>{{$_strings.order.LABEL_CANCEL_ORDER}}</h3>
        <v-row>
          <v-col class="d-flex pa-0 ma-0 justify-end">
            <v-toolbar-items>
              <v-btn
                icon
                color="black"
                dark
                @click="dialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="mt-4">
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-row>
            <v-col class="pb-0">
              <common-auto-complete-items
                v-if="!showInputReason"
                type="reasonCancelOrder"
                searchName="name"
                item-text="reason"
                item-value="reason"
                v-model="form.notes"
                dense
                outlined
                clearable
                @updateItems="updateReasonList"
                placeholder="Pilih alasan pembatalan"
                :filter="filterReasonList"
                :items="reasonList"
                :rules="[v => !!v || $_strings.messages.validation.REQUIRED('Alasan pembatalan')]"
              />
              <v-checkbox
                v-model="showInputReason"
                @change="form.notes = ''"
                label="Lainnya"
              ></v-checkbox>
              <v-textarea
                v-if="showInputReason"
                no-resize
                outlined
                dense
                v-model="form.notes"
                rows="5"
                :counter="255"
                :rules="[(v) => rulesNotes(v, true, 255, 'Alasan pembatalan')]"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="red"
          class="white--text"
          @click="dialog = false"
        >
          {{$_strings.common.CANCEL}}
        </v-btn>
        <v-btn
          color="primary"
          @click="cancelOrder"
          :disabled="!form.notes"
          :loading="isLoading"
        >
          {{$_strings.common.YES}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { rulesNotes, getDateTimeTz } from '@/helper/commonHelpers';

export default {
  watch: {
    dialog(newVal) {
      if (!newVal) {
        this.$refs.form.reset();
        this.form.notes = '';
      }
    },
  },
  data() {
    return {
      isLoadingReasonList: false,
      showInputReason: false,
      valid: false,
      dialog: false,
      isLoading: false,
      rules: {
        notes: [
          // NOTES REQUIRED
          (v) => !!v || this.$_strings.messages.validation.REQUIRED(this.$_strings.order.NOTE),
          // CHARACTER LENGTH MAX 255
          (v) => (v && v.length > 255 ? 'Jumlah karakter maksimum 255' : true),
        ],
      },
      order: {},
      form: {
        notes: '',
        status: 'DIBATALKAN',
        datetimeTz: this.getDateTimeTz(),
      },
      reasonList: [],
      filterReasonList: {
        name: '',
        page: 0,
        size: 25,
        totalData: 0,
      },
    };
  },
  methods: {
    rulesNotes,
    getDateTimeTz,
    updateReasonList(items) {
      this.reasonList = [...this.reasonList, ...items];
    },
    cancelOrder() {
      const valid = this.$refs.form.validate();
      if (valid) this.$emit('cancelOrder', this.order.id, this.form);
    },
  },
};
</script>
